@font-face {
  font-family: 'Lato';
  src: local("LatoLatin-Medium"), local("LatoLatin-Medium"), url("/content/fonts/LatoLatin-Medium.ttf"); }

body {
  background: #fff;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  color: #333; }

@font-face {
  font-family: 'FuturaStdHeavyOblique';
  src: local("FuturaStdHeavyOblique"), local("FuturaStdHeavyOblique"), url("/content/fonts/FuturaStdHeavyOblique.otf"); }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/*Student Image Hover*/
#applicant .image {
  width: 100% !important;
  height: 100% !important; }

#applicant .image img {
  -webkit-transition: all 1s ease !important;
  /* Safari and Chrome */
  moz-transition: all 1s ease !important;
  /* Firefox */
  ms-transition: all 1s ease !important;
  /* IE 9 */
  o-transition: all 1s ease !important;
  /* Opera */
  transition: all 1s ease !important; }

#applicant .image:hover img {
  -webkit-transform: scale(3.25) !important;
  /* Safari and Chrome */
  -moz-transform: scale(3.25) !important;
  /* Firefox */
  -ms-transform: scale(3.25) !important;
  /* IE 9 */
  o-transform: scale(3.25) !important;
  /* Opera */
  transform: scale(3.25) !important; }

/* ==========================================================================
Range
========================================================================== */
.range {
  position: relative;
  width: 550px;
  height: 5px; }

.range input {
  width: 100%;
  position: absolute;
  top: 2px;
  height: 0;
  -webkit-appearance: none; }
  .range input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important; }
  .range input::-moz-range-thumb {
    width: 18px;
    height: 18px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important; }
  .range input::-ms-thumb {
    width: 18px;
    height: 18px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #37adbf;
    cursor: pointer;
    border: 0 !important; }
  .range input::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #37adbf; }
  .range input::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #37adbf; }
  .range input::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #37adbf; }
  .range input:focus {
    background: none;
    outline: none; }
  .range input::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent; }

.range-labels {
  margin: 18px -41px 0;
  padding: 0;
  list-style: none; }
  .range-labels li {
    position: relative;
    float: left;
    width: 90.25px;
    text-align: center;
    color: #37adbf;
    font-size: 14px;
    cursor: pointer; }
    .range-labels li::before {
      position: absolute;
      top: -25px;
      right: 0;
      left: 0;
      content: "";
      margin: 0 auto;
      width: 9px;
      height: 9px;
      background: #37adbf;
      border-radius: 50%; }
  .range-labels .active {
    color: #37adbf; }
  .range-labels .selected::before {
    background: #37adbf; }
  .range-labels .active.selected::before {
    display: none; }

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    height: 42px;
    display: inline-block;
    margin-left: 20px;
    margin-right: auto; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/person-icon.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/person-icon.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover, .hand, [jh-sort-by] {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.well {
  background-color: #FFFFFF !important;
  border: #FFFFFF !important; }

/*          badges and labels       */
.label {
  background-color: #D1DADE;
  color: #5E5E5E;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none; }

.badge {
  background-color: #D1DADE;
  color: #5E5E5E;
  font-family: 'Arial';
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none; }

.label-primary, .badge-primary {
  background-color: #1ab394;
  color: #FFFFFF; }

.label-success, .badge-success {
  background-color: #1c84c6;
  color: #FFFFFF; }

.label-warning, .badge-warning {
  background-color: #f8ac59;
  color: #FFFFFF; }

.label-warning-light, .badge-warning-light {
  background-color: #f8ac59;
  color: #ffffff; }

.label-danger, .badge-danger {
  background-color: #ED5565;
  color: #FFFFFF; }

.label-info, .badge-info {
  background-color: #23c6c8;
  color: #FFFFFF; }

.label-inverse, .badge-inverse {
  background-color: #262626;
  color: #FFFFFF; }

.label-white, .badge-white {
  background-color: #FFFFFF;
  color: #5E5E5E; }

.label-white, .badge-disable {
  background-color: #2A2E36;
  color: #8B91A0; }

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px; }

.widget.style1 h2 {
  font-size: 30px; }

.widget h2,
.widget h3 {
  margin-top: 5px;
  margin-bottom: 0; }

.widget-text-box {
  padding: 20px;
  border: 1px solid #e7eaec;
  background: #ffffff; }

.widget-head-color-box {
  border-radius: 5px 5px 0 0;
  margin-top: 10px; }

.widget .flot-chart {
  height: 100px; }

.yellow-bg {
  background-color: #1babb1;
  color: #ffffff; }

.btn-rounded {
  border-radius: 50px; }

/*  VARIAVEIS PARA PERSONALIZAR APP   */
.navbar-default {
  background-color: #008E6F !important;
  border: #fff !important; }

#small-chat {
  position: fixed;
  bottom: 52px;
  right: 20px;
  z-index: 100; }

#small-chat .badge {
  position: absolute;
  top: -3px;
  right: -4px; }

.open-small-chat {
  height: 38px;
  width: 38px;
  display: block;
  background: #008E6F;
  padding: 9px 8px;
  text-align: center;
  color: #fff;
  border-radius: 50%; }

.open-small-chat:hover {
  color: white;
  background: #00a5e9; }

.small-chat-box.ng-small-chat {
  display: block; }

.body-small .small-chat-box {
  bottom: 70px;
  right: 20px; }

.small-chat-box.active {
  display: block; }

.product-imitation {
  text-align: center;
  padding: 90px 0;
  background-color: #f8f8f9;
  color: #bebec3;
  font-weight: 600; }

.product-box {
  padding: 0;
  border: 1px solid #e7eaec; }

.product-box:hover,
.product-box.active {
  border: 1px solid transparent;
  -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
  -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
  box-shadow: 0 3px 7px 0 #a8a8a8; }

.hrmenu {
  color: #008E6F;
  margin-right: 50px;
  font-size: 16pt;
  font-family: 'FuturaStdHeavyOblique'; }

.home-vacancies {
  font-family: 'FuturaStdHeavyOblique'; }

.sub-nav-parent {
  display: table; }

.sub-nav-child {
  display: table-cell;
  vertical-align: middle; }

.navbar-default .navbar-nav > .active > a {
  color: white !important;
  background-color: transparent; }

.navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent; }

.help-block {
  color: #d90416 !important; }

.vertical-hr {
  border-left: 1px solid #c2c2c2;
  height: 250px; }

.vertical-hrv {
  border-left: 1px solid #c2c2c2;
  height: 100px; }

.sub-nav-a {
  color: #FFCB08;
  margin-right: 50px;
  font-size: 16pt;
  font-family: 'FuturaStdHeavyOblique'; }

.sub-nav-a:hover {
  color: black;
  text-decoration: none; }

.sub-nav-a:active {
  color: black;
  text-decoration: none !important; }

form.submitted .ng-invalid {
  border: 1px solid #f00; }

/*      language flags      */
ul.mozpt {
  list-style-image: url("/content/images/moz.png"); }

ul.ukeng {
  list-style-image: url("/content/images/uk.png"); }

/*      footer      */
.footer.fixed_full {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  background: #008E6F;
  border-top: 1px solid #e7eaec;
  color: #ffffff; }

/*      spinner     */
.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #008E6F;
  border-bottom: 16px solid #008E6F;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*      widgetss        */
.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px; }

.widget.style1 h2 {
  font-size: 30px; }

.navy-bg {
  background-color: #008E6F;
  color: #ffffff; }

.blue-bg {
  background-color: #02b9ff;
  color: #ffffff; }

.pink-bg {
  background-color: #ff79ce;
  color: #ffffff; }

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429; }

#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em; }

#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #f1f1f1; }

.vertical-timeline-content .btn {
  float: right; }

#vertical-timeline.light-timeline:before {
  background: #e7eaec; }

.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent; }

.dark-timeline.center-orientation .vertical-timeline-content:before {
  border-color: transparent transparent transparent #f5f5f5; }

.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent; }

.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5; }

@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em; }
  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px; } }

@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent; } }

.vertical-timeline-block {
  position: relative;
  margin: 2em 0; }

.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both; }

.vertical-timeline-block:first-child {
  margin-top: 0; }

.vertical-timeline-block:last-child {
  margin-bottom: 0; }

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0; }
  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0; }
  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0; } }

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center; }

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px; }

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px; }
  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px; }
  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden; } }

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em; }

.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both; }

.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px; }

.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6; }

.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500; }

.vertical-date small {
  color: #1ab394;
  font-weight: 400; }

.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white; }

@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px; }
  .vertical-timeline-content p {
    font-size: 13px; } }

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%; }
  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white; }
  .center-orientation .vertical-timeline-content .btn {
    float: left; }
  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px; }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
    float: right; }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white; }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
    float: right; }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
    left: auto;
    right: 122%;
    text-align: right; }
  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden; } }

.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent; }

.dark-timeline.center-orientation .vertical-timeline-content:before {
  border-color: transparent transparent transparent #f5f5f5; }

.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent; }

.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5; }

.test {
  background-color: linen;
  border-bottom: 2px solid #0792c2; }

.nav-pills > .active > a, .nav-pills > .active > a:hover {
  background-color: #008E6F !important;
  color: white; }

div.show-image {
  position: relative;
  margin: 5px; }

div.show-image:hover img {
  opacity: 0.5; }

div.show-image:hover input {
  display: block;
  margin-left: auto;
  margin-right: auto; }

div.show-image input {
  position: absolute;
  display: none; }

div.show-image input.update {
  left: 30%;
  top: 40%; }
